import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
export default function ProductList() {
    const [apiData, setApiData] = useState([]);
    const [loading,setLoading]=useState([true])

    

    useEffect(()=>{
        const fetchdata =async ()=>{

            try{
                const response = await axios.get('https://mysuperweb.xyz/')

                setApiData(response.data)
            }
            catch(err){
                console.log(err)

            }
            finally{
                setLoading(false)
            }

        }
        fetchdata()
    })
    return (
        <div >
            {loading && <p>Loading...</p>}
            <div >


                <div className='row gy-5 ' style={{padding:'20px'}}>


                    {apiData.map((item) => (



                        <div className='col-6 col-md-3' key={item.id} >
                            <Link to={`/ProductDetailsC/${item.id}`}>
                                <div className="card" style={{ backgroundColor: '#E3F3FF', border: 'none', display: 'flex', justifyContent: 'end', borderRadius: '12%' }}>
                                    
                                    <Image src={`products/${item.image}`} fluid style={{ border: '3px #C0D0E0 dashed', borderRadius: '12%'}} />

                                    <div className="card-img-overlay p-0 text-end">
                                        {item.isSale ? (<div ><Button className="rounded-pill" variant="danger" style={{ backgroundColor: 'red' }}>Sale!</Button></div>) : null}


                                    </div>
                                </div>
                            </Link>

                            


                            <div style={{ textAlign: 'center',paddingTop:'10px' }}>
                                <div style={{ color: '#6EC1E4', textAlign: 'center',fontSize:'2vh',fontWeight:'bold' }}>{item.name}</div>
                                <div  style={{ color: '#6EC1E4', textAlign: 'center',fontSize:'1.9vh',fontWeight:'bold' }}>
                                    {item.offer_price > 0 ? <span style={{ opacity: '0.5' }} className='text-decoration-line-through'>{item.offer_price.toFixed(2)}MYR</span> : null}
                                    <br></br>
                                    <span>{item.price.toFixed(2)}MYR</span>

                                </div>





                            </div>



                        </div>
                    ))}

                </div>

            </div>
        </div>
    )
}
