import React from 'react'
import Navbar from '../components/navbar'
import Image from 'react-bootstrap/Image';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet-async';
export default function aboutus() {
    
    const currentDomain = window.location.origin;
    return (
        <div>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="Learn more about our company." />
                <link rel='canonical' href={currentDomain +'/AboutUs'} />
            </Helmet>
            
            <Navbar page={'AboutUs'} />
            <div style={{ backgroundImage: 'linear-gradient(to bottom, #fafafa,#dfedfc)',paddingTop:'50px'}}>
            
            

                <div style={{ textAlign: 'center', padding: '30px' }}>
                    <Image src="images/kangaroo-top-img.png" width={'57px'} fluid />
                    <h1 className='fs-2 fw-bold'>About Us</h1>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className='rounded-4' style={{ margin: '20px', maxWidth: '1130px', border: '5px #C0D0E0 dashed', borderRadius: '12%', overflow: 'hidden' }}>
                        <div className='row' >
                            <div className='col-md-7' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '40px', backgroundImage: `url("images/annie-spratt-zA7I5BtFbvw-unsplash.jpg")` }}>
                                <div style={{ maxWidth: '460px' }}>
                                    <h3 style={{ color: '#ff8900', padding: '5px' }}>Nurturing Care for Little Ones</h3>
                                    <p style={{ padding: '5px' }} className='fw-bold'>
                                        Welcome to Kangaroo Care Malaysia! We are thrilled to have you here as part of our loving community of parents and caretakers.
                                    </p>
                                    <p style={{ padding: '5px' }} className='fw-bold'>
                                        At Kangaroo Care Malaysia, we understand the importance of providing the best care and comfort for your precious little ones. That’s why we are devoted to offering you the highest quality baby rapid relief cream, specially formulated to soothe and protect your baby’s delicate skin.
                                    </p>
                                </div>

                            </div>
                            <div className='col-md-5' style={{ padding: '0', backgroundImage: `url("images/annie-spratt-zA7I5BtFbvw-unsplash.jpg")` }}>
                                <Image src="images/Kangaroo-Care-4-1.jpg" style={{ borderRadius: '0 100px 0 0' }} fluid />
                            </div>
                        </div>

                    </div>
                </div>

                <section style={{ marginTop: '100px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ maxWidth: '1130px', }}>


                            <div className='row'>
                                <div className='col-md-4' style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', padding: '30px' }}>
                                    <div>


                                        <h3 style={{ color: '#ff8900', padding: '5px', textAlign: 'center' }}>Our Mission</h3>
                                        <p style={{ padding: '5px' }} className='fw-bold'>
                                            We created this page to be a go-to resource for all things baby care. Our baby rapid relief cream is here to make your little one’s life more comfortable.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <Image src="images/about-us-section-bg.png" style={{ maxWidth: '300px' }} fluid />
                                </div>
                                <div className='col-md-4' style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', padding: '30px' }}>
                                    <div >
                                        <h3 style={{ color: '#ff8900', padding: '5px', textAlign: 'center' }}>Our Quality</h3>
                                        <p style={{ padding: '5px' }} className='fw-bold'>
                                            Rest assured that our baby rapid relief cream is crafted with utmost care and attention to detail. It is dermatologist-tested, infused with natural ingredients, and safe for your baby’s delicate skin. We believe in delivering products that we trust and would use on our own precious bundles of joy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section style={{ marginTop: '100px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ maxWidth: '1130px', }}>


                            <div className='row'>
                                <div className='col-md-8'>
                                    <Image src="images/about-us-section-img-300x193.png" fluid />
                                </div>
                                <div className='col-md-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
                                    <div >
                                        <h3 style={{ color: '#ff8900', padding: '5px' }}>Join Our Community</h3>
                                        <p style={{ padding: '5px' }} className='fw-bold'>
                                            This page is not just about selling products – it’s about building a supportive community. We invite you to engage with us and fellow parents, sharing your experiences, tips, and stories. Together, we can navigate parenthood and provide the best for our little angels.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#0099ff" fillOpacity="0.15" d="M0,224L20,202.7C40,181,80,139,120,112C160,85,200,75,240,74.7C280,75,320,85,360,106.7C400,128,440,160,480,154.7C520,149,560,107,600,101.3C640,96,680,128,720,133.3C760,139,800,117,840,144C880,171,920,245,960,245.3C1000,245,1040,171,1080,149.3C1120,128,1160,160,1200,192C1240,224,1280,256,1320,224C1360,192,1400,96,1420,48L1440,0L1440,320L1420,320C1400,320,1360,320,1320,320C1280,320,1240,320,1200,320C1160,320,1120,320,1080,320C1040,320,1000,320,960,320C920,320,880,320,840,320C800,320,760,320,720,320C680,320,640,320,600,320C560,320,520,320,480,320C440,320,400,320,360,320C320,320,280,320,240,320C200,320,160,320,120,320C80,320,40,320,20,320L0,320Z"></path>
                </svg>
            </div>

            <Footer />





        </div>
    )
}
