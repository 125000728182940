import { useContext, useState } from "react"
import { CartContext } from "./cartContext"
import { Button } from 'react-bootstrap'
import { CgMathMinus, CgMathPlus } from "react-icons/cg";

export default function QuantityBtn({ productInfo,display }) {

    const { cartItems, setCartItems } = useContext(CartContext)

    //購物車內有冇該產品
    let productIndexInCart = cartItems.findIndex((element) => {
        return element.id === productInfo.id
    })
    //findIndex()
    //如果係購物車內找到該件產品 => 返回索引位置 0, 1, 2, 3.....
    //該件產品沒有被加入過去購物車 => 返回 -1

    let [numInCart, setNumInCart] = useState(
        (productIndexInCart === -1) ? 0 : cartItems[productIndexInCart].quantity
    )

  

    const handleAdd = () => {

        if (productIndexInCart === -1) {
            //購物車本身沒有，在cartItems array中加個新element (object)
            setCartItems(
                [{
                    id: productInfo.id,
                    name: productInfo.name,
                    image: productInfo.image,
                    price: productInfo.price,
                    quantity: 1
                },
                ...cartItems]
            )
        }
        else {
            //購物車有該產品，只加個quantity
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity++
            setCartItems(newCartArray)
        }

        setNumInCart(numInCart + 1)
    }

    const handleSubtract = () => {

        if (cartItems[productIndexInCart].quantity === 1) {
            //購在物車中只剩一件的話，remove object
            let newCartArray = [...cartItems]
            newCartArray.splice(productIndexInCart, 1)
            setCartItems(newCartArray)
        }
        else {
            //只減個quantity
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity--
            setCartItems(newCartArray)
        }

        setNumInCart(numInCart - 1)
    }

    const handleRemove=()=>{

        if (cartItems[productIndexInCart].quantity>0) {
            //購在物車中只剩一件的話，remove object
            let newCartArray = [...cartItems]
            newCartArray.splice(productIndexInCart, 1)
            setCartItems(newCartArray)
        }
        
    }

    return (
        <div className="addToCart" >
            {
                (numInCart === 0) ?
                    <Button
                        variant="primary"
                        className="rounded-pill fw-semibold"
                        style={{
                            backgroundColor: '#6EC1E4',
                            color: 'black',
                            border: 'none',
                            padding: '24px 60px',
                        }}
                        onClick={handleAdd}
                    >
                        ADD TO CART
                    </Button> :
                    <div style={{display:'flex',alignItems:'center'}}>
                        {/* 数量加减按钮 */}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems:'center',
                                border: '1px rgba(0, 0, 0, 0.1) solid',
                                minWidth: '95px',
                                maxWidth: '95px',
                                minHeight: '34px',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                padding:'10px 0'

                            }}
                        >
                            <button
                                style={{
                                    padding: '1px 6px',
                                    background: 'transparent',
                                    border: 'none',
                                }}
                                onClick={handleSubtract}
                            >
                                <CgMathMinus />
                            </button>
                            <div >{numInCart}</div>
                            <button
                                style={{
                                    padding: '1px 6px',
                                    background: 'transparent',
                                    border: 'none',
                                }}
                                onClick={handleAdd}
                            >
                                <CgMathPlus />
                            </button>
                        </div>
                        <span onClick={handleRemove} style={{color:'#8f8f8f',fontSize:'7px',marginLeft:'5px',cursor:'pointer',letterSpacing:'1px',display:display}}>REMOVE</span>
                    </div>
                    
            }
        </div>
    )
}
