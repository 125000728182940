import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { CiSearch } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
export default function Test() {

  const [data, setData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [searchData, setSearchData] = useState([])
  const [isPageVisible, setIsPageVisible] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('https://mysuperweb.xyz/')
        setData(res.data)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [])

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    if (searchValue.trim() === '') {
      setSearchData([])
    } else {
      const filter = data.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
      setSearchData(filter)
    }
  }, [searchValue, data])

  const handleClosePage = () => {
    setIsPageVisible(false);
  };

  if (!isPageVisible) {
    return null; // 隐藏页面
  }

  

  return (
    <div style={{zIndex:'5',position:'absolute',width:'100%'}}>
    <div className='p-3 bg-body-tertiary' >

      <div className='row ' style={{display:'flex',alignItems:'center'}}>
      <div className='col-md-2 p-3' style={{textAlign:'center'}}><img
                src="./images/ori-kangaroo-logo-with-tm-02.png"  // 根据实际路径调整
                alt="logo"
                width={'150px'}
            
              /></div>
      <div className='col-11 col-md-8'><InputGroup  >
                  <InputGroup.Text><CiSearch /></InputGroup.Text>
                  <Form.Control onChange={handleChange} aria-label="search" />
                </InputGroup></div>
      <div className='col-1 col-md-2'><IoMdClose onClick={handleClosePage}/></div>
      </div>


      <div style={{ padding: '50px' }}>


        <div className='row gy-5'>


          {searchData.map((item) => (



            <div className='col-md-3' key={item.id} >
              <div className="card" style={{ backgroundColor: '#E3F3FF', border: 'none', display: 'flex', justifyContent: 'end', borderRadius: '12%' }}>
                <Image src={`products/${item.image}`} fluid style={{ border: '3px #C0D0E0 dashed', borderRadius: '12%' }} />
                <div className="card-img-overlay d-flex justify-content-between">
                  {item.isSale ? (<div ><Button className="rounded-pill" variant="danger" style={{ backgroundColor: 'red' }}>Sale!</Button></div>) : null}


                </div>
              </div>


              <div style={{ textAlign: 'center' }}>
                <div className='fw-bold fs-5' style={{ color: '#6EC1E4', paddingBottom: '40px' }}>{item.name}</div>
                <div className='fw-bold fs-6' style={{ color: '#6EC1E4', paddingBottom: '20px' }}>
                  {item.offer_price > 0 ? <span style={{ paddingRight: '5px', opacity: '0.5' }} className='text-decoration-line-through'>RM{item.offer_price.toFixed(2)}</span> : null}
                  <span>RM{item.price.toFixed(2)}</span>

                </div>






              </div>



            </div>
          ))}

        </div>

      </div>
    </div>
    </div>
  )
}
