import React,{useState} from 'react'
import Aboutus from './client/aboutUs';
import Home from './client/home';
import OurStory from './client/ourStory';
import ShopNow from './client/shopNow';
import ContactUs from './client/contactUs';
import ShoppingCart from './client/shoppingCart';
import NotFound from './client/notFound';
import Test from './client/test'
import ProductDetailsC from './client/ProductDetails';
import { CartContext } from './components/cartContext';

import AdminHome from './admin/home'
import Product from './admin/product'
import ProductDetails from './admin/productDetail';
import AddProduct from './admin/addProduct';
import DeleteProduct from './admin/deleteProduct';
import Order from './admin/order';
import OrderDetails from './admin/orderDetails';



import { BrowserRouter, Routes, Route } from 'react-router-dom';


export default function App() {

  const [cartItems, setCartItems] = useState([])

  return (
    <div>
      <BrowserRouter>
        <CartContext.Provider value={{ cartItems, setCartItems }}>
          <Routes>

            <Route path='/' element={<Home />}></Route>
            <Route path='/AboutUs' element={<Aboutus />}></Route>
            <Route path='/ContactUs' element={<ContactUs />}></Route>
            <Route path='/OurStory' element={<OurStory />}></Route>
            <Route path='/ShopNow' element={<ShopNow />}></Route>
            <Route path='/ShoppingCart' element={<ShoppingCart />}></Route>
            <Route path='*' element={<NotFound />} />
            <Route path='/AdminHome' element={<AdminHome />}></Route>
            <Route path='/Product' element={<Product />}></Route>
            <Route path='/ProductDetails' element={<ProductDetails />}>
              <Route path=":id" element={<ProductDetails />} />
            </Route>
            <Route path='/AddProduct' element={<AddProduct />}></Route>
            <Route path='/DeleteProduct' element={<DeleteProduct />}></Route>
            <Route path='/Order' element={<Order />}></Route>
            <Route path='/OrderDetails' element={<OrderDetails />}>
              <Route path=":id" element={<OrderDetails />} />
            </Route>
            <Route path='/ProductDetailsC' element={<ProductDetailsC />}>
              <Route path=":id" element={<ProductDetailsC />} />
            </Route>

            <Route path='/Test' element={<Test />}></Route>
            {/* 
            
            
            
            


            <Route path='/test' element={<Test />}></Route>
             */}
          </Routes>
        </CartContext.Provider>
      </BrowserRouter>
    </div>
  )
}
