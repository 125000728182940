import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Footer from '../components/footer';
import ProductList from '../components/ProductList';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';




export default function Home() {

    const [windowWidth, setWindowWidth1] = useState(window.innerWidth);
    const [backgroundHeight, setBackgroundWidth] = useState(window.innerWidth * 1.77);
    const [backgroundHeight1, setBackgroundWidth1] = useState(window.innerWidth * 0.5625);



    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setWindowWidth1(width);
            setBackgroundWidth(width * 1.77);
            setBackgroundWidth1(width * 0.5625);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    const currentDomain = window.location.origin;

    return (
        <div >
            <Helmet>
                <title>Home</title>
                <meta name="description" content="Welcome to our home page." />
                <link rel='canonical' href={currentDomain} />
            </Helmet>



            <section id='banner1' >
                <Navbar page={'Home'} color={'white'} />

                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 15000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        
                        <div style={{
                            height: windowWidth <= 750 ? `${backgroundHeight}px` : `${backgroundHeight1}px`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: windowWidth <= 750 ? `url("images/pepo2.webp")` : `url("images/pepo.webp")`,
                            position: 'relative'
                        }}>
                            <div style={{ position: 'absolute', bottom: '100px', color: 'white', padding: '20px' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '25px', textTransform: 'uppercase' }}>Baby Rapid Relief Cream</div>
                                <div style={{ paddingTop: '20px', textDecoration: 'none' }}>< Link style={{ textDecoration: 'none', color: 'white' }} to='/ShopNow'>SHOP <FaArrowRight /></Link></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div style={{
                            height: windowWidth <= 750 ? `${backgroundHeight}px` : `${backgroundHeight1}px`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: windowWidth <= 750 ? `url("images/pepo2.webp")` : `url("images/pepo.webp")`,
                            position: 'relative'
                        }}>
                            <div style={{ position: 'absolute', bottom: '100px', color: 'white', padding: '20px' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '25px', textTransform: 'uppercase' }}>Baby Rapid Relief Cream</div>
                                <div style={{ paddingTop: '20px', textDecoration: 'none' }}>< Link style={{ textDecoration: 'none', color: 'white' }} to='/ShopNow'>SHOP <FaArrowRight /></Link></div>
                            </div>
                        </div>
                    </SwiperSlide>

                </Swiper>


            </section>

            <section  style={{ margin: '20px' }}>
                <div style={{ backgroundImage: `url("images/kangaroo-home-section-2.jpg")`, backgroundRepeat: 'none', backgroundSize: 'cover', backgroundPosition: 'right' }}>


                    <div className='row' >
                        <div className='col-md-6'></div>
                        <div className='col-md-6' style={{ padding: '80px 40px 80px 40px' }}>
                            <h2 style={{ color: '#ff8900' }}>Top Selling Relief Cream</h2>
                            <p >At Kangaroo Care Malaysia, we understand the importance of providing the best care and comfort for your precious little ones. That’s why we are devoted to offering you the highest quality baby rapid relief cream, specially formulated to soothe and protect your baby’s delicate skin.</p>
                            <Button variant="primary" style={{ backgroundColor: '#ff8900', border: 'none', paddingLeft: '30px', paddingRight: '30px' }}>Read More</Button>
                        </div>
                    </div>
                </div>

            </section>

            <section style={{ margin: '20px' }}>

                <div className='row g-3'>
                    <div className='col-md-6'><Image src='images/kangaroo-homepage-square-img-02.jpg' fluid /></div>
                    <div className='col-md-6'><Image src='images/kangaroo-homepage-square-img-03-1536x1536.jpg' fluid /></div>
                </div>
            </section>

            <section>



                <div style={{ backgroundColor: '#E3F3FF' }}>

                    <div style={{ textAlign: 'center' }}>
                        <div className='fs-2 fw-bold' style={{ padding: '5px' }}>Our products</div>

                        <div className='fw-semibold' style={{ padding: '5px' }}>Gentle relief for little ones</div>
                        <div style={{ padding: '5px' }}><Image src='images/kangaroo-star.png' fluid /></div>


                    </div>
                    <div className='row gy-5 container-fluid'>


                        <ProductList/>

                    </div>

                </div>





            </section>

            <Footer />





        </div>

    )
}
