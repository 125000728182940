import React, { useEffect, useRef, useState } from 'react';
import Nav from './navbar.js'
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Alert} from 'antd';

export default function Product() {

    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState([true])

    const ChangeView = async (id) => {
        try {
            
            const response = await axios.put(`https://mysuperweb.xyz/ChangeView`,{id});
           
            console.log('View changed successfully:', response.data);
            // 如果需要在更改視圖後執行其他操作，可以在這裡添加代碼
        } catch (error) {
            console.error('Error while changing view:', error);
            // 如果發生錯誤，您可以在這裡處理錯誤，例如顯示錯誤消息給用戶
        }
    };


    useEffect(() => {
        const fetchdata = async () => {

            try {
                const response = await axios.get('https://mysuperweb.xyz/order')

                setApiData(response.data.reverse())
            }

            finally {
                setLoading(false)
            }

        }
        fetchdata()
    },[])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [

        {
            title: 'Customer ID',
            dataIndex: 'CustomerID',
            key: 'CustomerID',
            width: '10%',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Order Date',
            dataIndex: 'OrderDate',
            key: 'OrderDate',
            width: '30%',
            ...getColumnSearchProps('OrderDate'),
            render: (text) => {
                // 将 text 转换为日期时间对象
                const date = new Date(text);
                // 格式化为 "YYYY-MM-DD HH:mm:ss" 格式的字符串
                const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                // 返回格式化后的日期时间字符串
                return <span style={{ fontSize: '10px' }}>{formattedDate}</span>;
            },
        },
        {
            title: 'Total Amount',
            dataIndex: 'TotalAmount',
            key: 'TotalAmount',
            width: '30%',
            ...getColumnSearchProps('TotalAmount'),
            render: (text) => <span style={{ fontSize: '10px' }}>{text.toFixed(2)} MYR</span>,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            width: '30%',
            ...getColumnSearchProps('Status'),
            render: (text) => <span style={{ fontSize: '10px' }}>{text===0?<Alert message="Unread" type="warning" showIcon  />:<Alert message="Read" type="success" showIcon />}</span>,
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 80,
            render: (record) => <Link style={{textDecoration:'none'}} onClick={()=>ChangeView(record.OderID)} to={`/OrderDetails/${record.OderID}`}>View</Link>,
        },


    ]
    return (
        <div>
            <Nav />
            {loading && <p>Loading...</p>}
          
            <Table columns={columns} dataSource={apiData} scroll={{ x: 'max-content' }} />
        </div>
    )
}
