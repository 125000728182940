import React from 'react'
import Navbar from '../components/navbar'
import Image from 'react-bootstrap/Image';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet-async';

export default function ContactUs() {
  const currentDomain = window.location.origin;
  return (
    <div>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Get in touch with us." />
        <link rel='canonical' href={currentDomain+'/ContactUs'} />
      </Helmet>
      <Navbar page={'ContactUs'} />
      <section style={{ backgroundImage: 'linear-gradient(to bottom, #fafafa,#dfedfc)',paddingTop:'50px' }}>
      
        <div style={{ textAlign: 'center', padding: '30px' }}>
          <Image src="images/kangaroo-top-img.png" width={'57px'} fluid />
          <p className='fs-2 fw-bold'>Contact Us</p>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className='rounded-4' style={{ margin: '20px', maxWidth: '1130px', border: '5px #C0D0E0 dashed', borderRadius: '12%', overflow: 'hidden' }}>
            <div className='row' >
              <div className='col-md-7' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '40px', backgroundImage: `url("images/annie-spratt-zA7I5BtFbvw-unsplash.jpg")` }}>
                <div style={{ maxWidth: '460px' }}>
                  <h3 style={{ color: '#ff8900', padding: '5px' }}>Thank you for choosing Kangaroo Care Malaysia</h3>
                  <p style={{ padding: '5px' }} className='fw-bold'>
                    We are honored to be a part of your baby’s care routine. If you have any questions or need assistance, please don’t hesitate to reach out to us. We are here to support you every step of the way!
                  </p>
                </div>

              </div>
              <div className='col-md-5' style={{ padding: '0' }}>
                <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15890.226701617126!2d100.275908!3d5.331616!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304ac03dbe703f81%3A0x746da1b2374ffc4f!2sGolden%20Triangle%20Condominium!5e0!3m2!1sen!2sus!4v1709295171750!5m2!1sen!2sus" width="600" height="450" allowFullScreen="" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
              </div>
            </div>

          </div>
        </div>



        <section className="contact" style={{ padding: '3% 7%' }}>
          <div className="text-center text-uppercase fs-1 fw-bold py-3" style={{ color: '#ff6e01' }}>Get In Touch</div>

          <form>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">Name</label>
                <input type="text" className="form-control" id="name" aria-describedby="emailHelp" />
              </div>
              <div className="col-md-6">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" aria-describedby="emailHelp" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="number" className="form-label">Number</label>
                <input type="text" className="form-control" id="number" aria-describedby="emailHelp" />
              </div>
              <div className="col-md-6">
                <label htmlFor="subject" className="form-label">Subject</label>
                <input type="text" className="form-control" id="subject" aria-describedby="emailHelp" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label htmlFor="comments" className="form-label">Comments</label>
                <textarea className="form-control" placeholder="Leave a comment here" id="comments"></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <button type="button" style={{ borderRadius: '25px', color: 'white', textTransform: 'capitalize' }} className="btn btn-warning px-4 my-4">Submit</button>
              </div>
            </div>
          </form>
        </section>


      </section>



      <Footer />
    </div>
  )
}
