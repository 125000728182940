import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Image } from 'antd';
import Navbar from '../components/navbar';
import QuantityButton from '../components/quantityButton';
import Accordion from 'react-bootstrap/Accordion';
import { FaShuttleVan } from "react-icons/fa";
import Footer from '../components/footer';


export default function ProductDetails() {

  const { id } = useParams();
  const [data, setData] = useState([])
  const [loading,setLoading]=useState([true])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://mysuperweb.xyz/ProductDetailsC/${id}`);
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
      finally{
        setLoading(false)
    }
    };

    fetchData();
  }, [id]);

  console.log(data)

  return (
    <div  >
      <Navbar page={'ShopNow'} />
      
      <div class="container-fluid" style={{ backgroundImage: 'linear-gradient(to bottom, #fafafa,#dfedfc)', paddingTop: '100px' }}>
      {loading && <p>Loading...</p>}
        {data.map((item) => (
          <div className='row'>
            <div className='col-md-6' >
            <Image.PreviewGroup
  >
    <Image
      width={'100%'}
      style={{padding:'5%'}}
      src={`/products/${item.image}`}
    />
  </Image.PreviewGroup></div>
            <div className='col-md-6'>
              <div style={{ paddingTop: '5%' ,paddingBottom:'5%'}}>
                <div style={{ fontSize: '20px', paddingBottom: '10px' ,fontWeight:'bold'}}>{item.name}</div>
                <div style={{fontSize:'13px',paddingBottom: '20px',fontWeight:'bold'}}>{item.price.toFixed(2)} MYR</div>
                
                <div ><QuantityButton productInfo={item} display={'none'} /></div>
                <hr></hr>
                <p style={{fontSize:'15px'}}><FaShuttleVan /> Free shipping on orders over RM150</p>
                
                <Accordion flush>
                  <Accordion.Item eventKey="0" >
                    <Accordion.Header>Description</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <div style={{ fontSize: '30px', color: 'grey' }}>Active ingredient</div>
                        <p>Tocopheryl Acetate (Vitamin E): Moisturizing and antioxidant ingredients that can improve skin thickness and strength.
                          Oat Extract: Relieves itchy and dry skin, bringing soothing and calming itchiness effects.
                          Squalene Oil: Good for keeping baby’s skin hydrated and moisturized.
                          Citrus Limon Peel Oil: Clarifies the skin and provides protection against harmful bacteria.
                          Eucalyptus Oil: Helps relief from uneasy feelings.
                          Ginger Oil: Help to comfort digestive upsets
                          Tea Tree Oil: Anti-inflammatory and anti-itching properties, help to reducing fungal infections and rash irritation.
                          Lavender Oil: Provides calming effects, promotes skin relaxation, antibacterial and antifungal properties</p>
                        <div style={{ fontSize: '30px', color: 'grey' }}>Dermatologically tested</div>
                        <ul>
                          <li>KKM Certified</li>
                          <li>COA Certified</li>
                          <li>MSDS Certified</li>
                        </ul>
                        <p>
                          Paraben and SLS Free
                          <br></br>
                          Phthalates and Artificial Fragrance Free
                          <br></br>
                          PH-Balanced
                          <br></br>
                          Not tested on animals
                          <br></br>
                          Safe on sensitive baby skin
                          <br></br>
                          Soothes, hydrates and protects skin without leaving any oily residues
                          <br></br>
                          Absorbs almost immediately into skin
                        </p>

                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header> Additional Infomation</Accordion.Header>
                    <Accordion.Body>
                      <div style={{ fontSize: '20px', color: 'grey' }}>To apply our baby relief cream, follow these simple steps:</div>
                      <br></br>

                      <ol>
                        <li>Clean and dry: Start by cleaning and thoroughly drying the affected area on your baby’s skin. Ensure that the area is clean from any dirt or moisture.</li>
                        <br></br>
                        <li> Take a small amount: Squeeze a pea-sized amount of the baby relief cream onto your clean fingertips. After bath or before sleep .</li>
                        <br></br>
                        <li>Gently apply: Using gentle, circular motions, apply the cream onto the affected area. If you are applying it for relief from cough or flu, you can focus on the chest and back area.</li>
                        <br></br>
                        <li> Massage in: Gently massage the cream into your baby’s skin using light pressure. This will help the cream to absorb and get to work.</li>
                        <br></br>
                        <li>Repeat as needed: Depending on the severity of the discomfort, you can reapply the baby relief cream up to three times a day.</li>
                      </ol>

                      <p>
                      <div style={{ fontSize: '20px', color: 'grey' }}>Tip: For the best results, we recommend using our baby relief cream consistently to show best result.</div>
                        
                        <br></br>
                        
                        Recommend for 3 months and above.

                        <br></br>
                        <br></br>

                        If you have any further questions or concerns about the application of our baby relief cream, please feel free to reach out to us. We are here to help!
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

            </div>
          </div>

        ))}
      </div>



          <Footer/>

    </div>

  )

}