import React, {  useRef, useState } from 'react'
import { Form, Button, Image } from 'react-bootstrap'
import axios from 'axios'
import { message } from 'antd';
import Navbar from './navbar';
export default function AddProduct() {
    const [messageApi, contextHolder] = message.useMessage();
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const name = useRef(null);
    const price = useRef(null);
    const offerPrice = useRef(null);
    const isSale = useRef(null);

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile); // 更新文件状态
        // 创建临时URL
        const imageUrl = URL.createObjectURL(uploadedFile);
        // 设置照片URL
        setImageUrl(imageUrl);
      };

      const handleEdit = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('image', file);
        formData.append('name', name.current.value);
        formData.append('price', price.current.value);
        formData.append('offerPrice', offerPrice.current.value);
        formData.append('isSale', isSale.current.value);
    
        try {
            const response = await axios.post('https://mysuperweb.xyz/addProduct', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            messageApi.open({
              type: 'success',
              content: 'Successfully updated',
            });
            console.log('File uploaded successfully:', response.data);
          } catch (error) {
            messageApi.open({
              type: 'warning',
              content: 'Update failed',
            });
            console.error('Error uploading file:', error);
          }
      };


    return (
        <div>
            <Navbar/>
            {contextHolder}
            <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>

                <div style={{ border: '3px #C0D0E0 dashed', borderRadius: '5%', padding: '10px' }}>
                    <h3 style={{ textAlign: 'center' }}>ADD PRODUCT</h3>
                    <form onSubmit={handleEdit}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}><Image src={imageUrl} width={'200px'} alt="product" fluid /></div>

                        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}><input type='file' name='image' onChange={handleFileChange} /></div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Label htmlFor="name">Name</Form.Label>
                                <Form.Control type="text" id="name" ref={name}  />
                            </div>

                            <div className='col-md-6'>
                                <Form.Label htmlFor="price">Price</Form.Label>
                                <Form.Control type='text'   id="price" ref={price} />
                            </div>

                            <div className='col-md-6'>
                                <Form.Label htmlFor="offerPrice">Offer price</Form.Label>
                                <Form.Control type='text'  id="offerPrice" ref={offerPrice}/>
                            </div>

                            <div className='col-md-6'>
                                <Form.Label htmlFor="isSale">is Sale?</Form.Label>
                                <Form.Control type='text'  id="isSale" ref={isSale}/>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}><Button style={{ width: '30%' }} type='submit' variant="outline-secondary">ADD</Button></div>


                    </form>


                </div>
            </div>
        </div>
    )
}
