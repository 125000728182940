import React, { useEffect, useState } from 'react'
import Nav from './navbar'
import { FaArrowRight} from "react-icons/fa";
import { BsHandbag ,BsBoxSeam} from "react-icons/bs";
import { Link } from 'react-router-dom';
import axios from 'axios'


export default function Home() {

    const[data,setData]=useState([])
    const [order,setOrder]=useState([])


    useEffect(()=>{
        axios.get('https://mysuperweb.xyz/')
        .then(res=>setData(res.data))
        .catch(err=>console.log(err))
    },[])

    useEffect(()=>{
        axios.get('https://mysuperweb.xyz/order')
        .then(res=>setOrder(res.data))
        .catch(err=>console.log(err))
    },[])

    return (
        <div>
            <Nav />

            <section style={{ padding: '0 1%' }}>
                <h3 style={{padding:'10px 0 30px 0'}}>Admin Dashboard</h3>

                <div className='row gy-3 pb-5' >
                <h3 style={{textAlign:'center'}}>Quantity Product : {data.length}</h3>
                    <div className='col-md-3'>
                        <div>
                            <div style={{ display: 'flex', borderRadius: '5px 5px 0 0', justifyContent: 'space-between',alignItems:'center', padding: '10px 10px 30px 10px', backgroundColor: 'rgb(21,144,164,0.8)' }}>
                                <div>
                                    
                                    <div style={{ color: 'white',fontWeight:'bold',fontSize:'20px' }}>Edit Product</div>
                                </div>
                                <div style={{ fontSize: '60px', display: 'flex', alignItems: 'center' }}><BsHandbag style={{ opacity: '0.5' }} /></div>
                            </div>
                            
                            <Link to='/Product' style={{textDecoration:'none'}}><div  style={{ display: 'flex', alignItems: 'center', borderRadius: '0 0 5px 5px ', justifyContent: 'center', color: 'white', backgroundColor: 'rgb(21,144,164)' }}>More info <FaArrowRight /></div></Link>
                        </div>

                    </div>
                    <div className='col-md-3'>

                        <div>
                            <div style={{ display: 'flex', borderRadius: '5px 5px 0 0', justifyContent: 'space-between',alignItems:'center', padding: '10px 10px 30px 10px', backgroundColor: 'rgb(36,150,62,0.8)' }}>
                                <div>
                                
                                    <div style={{ color: 'white',fontWeight:'bold',fontSize:'20px' }}>Add Product</div>
                                </div>
                                <div style={{ fontSize: '60px', display: 'flex', alignItems: 'center' }}><BsHandbag style={{ opacity: '0.5' }} /></div>
                            </div>
                            <Link to='/AddProduct' style={{textDecoration:'none'}}><div style={{ display: 'flex', alignItems: 'center', borderRadius: '0 0 5px 5px ', justifyContent: 'center', color: 'white', backgroundColor: 'rgb(36,150,62)' }}>More info <FaArrowRight /></div></Link>
                        </div>
                    </div>
                    <div className='col-md-3'>
                    <div>
                            <div style={{ display: 'flex', borderRadius: '5px 5px 0 0', justifyContent: 'space-between',alignItems:'center', padding: '10px 10px 30px 10px', backgroundColor: 'rgb(217,164,6,0.8)' }}>
                                <div>
                                 
                                    <div style={{ color: 'white',fontWeight:'bold',fontSize:'20px' }}>Delete Product</div>
                                </div>
                                <div style={{ fontSize: '60px', display: 'flex', alignItems: 'center' }}><BsHandbag style={{ opacity: '0.5' }} /></div>
                            </div>
                            <Link to="/DeleteProduct" style={{textDecoration:'none'}}><div style={{ display: 'flex', alignItems: 'center', borderRadius: '0 0 5px 5px ', justifyContent: 'center', color: 'white', backgroundColor: 'rgb(217,164,6)' }}>More info <FaArrowRight /></div></Link>
                        </div>
                    </div>

                </div>


                <div className='row gy-3 pb-5'>
                <h3 style={{textAlign:'center'}}>Order: {order.length}</h3>
                    <div className='col-md-3'>
                        <div>
                            <div style={{ display: 'flex', borderRadius: '5px 5px 0 0', justifyContent: 'space-between',alignItems:'center', padding: '10px 10px 30px 10px', backgroundColor: 'rgb(21,144,164,0.8)' }}>
                                <div>
                                    
                                    <div style={{ color: 'white',fontWeight:'bold',fontSize:'20px' }}>Order</div>
                                </div>
                                <div style={{ fontSize: '60px', display: 'flex', alignItems: 'center' }}><BsBoxSeam style={{ opacity: '0.5' }} /></div>
                            </div>
                            
                            <Link to="/Order" style={{textDecoration:'none'}}> <div style={{ display: 'flex', alignItems: 'center', borderRadius: '0 0 5px 5px ', justifyContent: 'center', color: 'white',backgroundColor: 'rgb(21,144,164)'}}>More info <FaArrowRight /></div></Link>
                        </div>

                    </div>
                    

                </div>
                
            </section>
        </div>
    )
}
