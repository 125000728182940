import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import QuantityBtn from "../components/quantityButton"
import { CartContext } from "../components/cartContext"
import { useContext } from "react"
import Navbar from '../components/navbar'
import { Image,Button ,ProgressBar} from 'react-bootstrap'
import axios from 'axios';



export default function Checkout() {




    const [agree, setAgree] = useState(false);

    const handleCheckboxChange = () => {
      setAgree(!agree); // 切换状态
    };



    let { cartItems , setCartItems} = useContext(CartContext)
  
    let cartEmpty = cartItems.length <= 0 ? true : false

    const ShippingFee = 10

    let grandTotal = cartItems.reduce((total, product)=>{
        return total += product.price*product.quantity
    },0)
    const freeShippingPrice = 150

    const handleOrder=()=>{

        axios.post('https://mysuperweb.xyz/order',{'grandTotal':grandTotal,cartItems})
        .then(res=>{
            console.log(res.data)
            setCartItems([]);
        })
        .catch(err=>console.log(err))

    }

    

    return (
        <>
            <Navbar />
        <div style={{padding:'100px 5% 5% 5%'}}> 

            {
                cartEmpty &&
                <div >
                    <p className='text-center fw-bold' style={{letterSpacing:'2px'}}>CART</p>
                    <p className='text-center fs-6'>Your Cart is Empty</p>
                    <div className='text-center ' style={{fontSize:'9px',margin:'3.5px',marginBottom:'50px'}}>You are {(freeShippingPrice-grandTotal).toFixed(2)} MYR away from free shipping.</div>
                    
                    <div style={{display:'flex',justifyContent:'center'}}><Button as={Link} to='/ShopNow' variant="outline-dark" >CONTINUE SHIPPING</Button></div>
                </div>
            }

            {
                !cartEmpty &&
                <div className="container">
                    <div className="cartSection row">
                        <div className='col-md-9' >
                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                <div className='text-center fw-bold' style={{letterSpacing:'2px'}}>CART</div>
                                <div style={{display:'flex',alignItems:'center'}}><Button as={Link} to='/ShopNow' variant="outline-dark" >CONTINUE SHIPPING</Button></div>
                            </div>
                            <hr></hr>
                            {
                                cartItems.map((product, index) => (
                                    <React.Fragment key={product.id} >

                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                        <div className='col-4  col-md-2' ><Image src={`products/${product.image}`} fluid /></div>
                                     
                                        
                                        <div className='col-8  col-md-10 row' style={{ display: 'flex', alignItems: 'center' }}>

                                            <div className='col-md-6 py-1' style={{ color: '#8f8f8f', fontSize: '12px',textAlign:'start' }}>{product.name}</div>
                                            <div className='col-md-3 py-1' style={{ fontSize: '10.8px', fontWeight: 'bold',textAlign:'start' }}>{(product.price).toFixed(2)} MYR</div>
                                            <div className='col-md-3 py-1' style={{display:'flex',justifyContent:'start'}}><QuantityBtn productInfo={product} /></div>

                                        </div>
                                        
                                        </div>
                                        <hr style={{ margin: '20px 0 20px 0' }}></hr>






                                    </React.Fragment>
                                ))
                            }
                        </div>
                        <div className='col-md-3'>
                            <div>

                            <div>
                                {freeShippingPrice-grandTotal>0?<div style={{fontSize:'10px',margin:'3.5px'}}>You are {(freeShippingPrice-grandTotal).toFixed(2)} MYR away from free shipping.</div>:<div style={{ fontSize: '10px', margin: '3.5px' }}>Congratulations! Your order qualifies for free shipping</div>}
                              
                                <ProgressBar variant='success' style={{height:'10px'}} now={`${(grandTotal / 150) * 100}`}  />
                                </div>
                                <hr></hr>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>Subtotal</div>
                                    <div>{grandTotal.toFixed(2)} MYR</div>
                                </div>
                                <br></br>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>Shipping</div>
                                    {grandTotal<150?<div>{ShippingFee.toFixed(2)} MYR</div>:<div>Free</div>}
                                    
                                </div>
                                <hr></hr>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>Total</div>
                                    <div>{(grandTotal + (grandTotal < 150 ? ShippingFee : 0)).toFixed(2)} MYR</div>

                                    
                                </div>
                                <br></br>
                                <div className="form-check" style={{fontSize:'15px'}}>
                                    <input checked={agree} onChange={handleCheckboxChange} className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" htmlFor="defaultCheck1" style={{cursor:'pointer'}}>
                                      
                                            I agree to the terms and conditions
                                        
                                        </label>
                                </div>
                                <div><Button variant="dark" onClick={handleOrder} disabled={!agree} style={{width:'100%',margin:'10px 0 10px 0',padding:'5px'}}>Check Out</Button></div>
                                
          
                            </div>
                        </div>

                    </div>

                </div>
            }
            </div>

        </>
    )
}
