import React, { useContext, useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/navbar.css'; // 引入您的 CSS 文件
import { Link } from 'react-router-dom';
import { CartContext } from './cartContext';
import { CiShoppingCart, CiSearch, CiMenuBurger } from "react-icons/ci"
import axios from 'axios'
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { IoMdClose } from "react-icons/io";







export default function Naavbar(props) {

  const isHomeActive = props.page === 'Home';
  const isAboutActive = props.page === 'AboutUs';
  const isOurStoryActive = props.page === 'OurStory';
  const isShopNowActive = props.page === 'ShopNow';
  const isContactUsActive = props.page === 'ContactUs';

  const { cartItems } = useContext(CartContext);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {


    const calculateQuantity = () => {
      let quantity = 0;

      cartItems.forEach((product) => {
        quantity += product.quantity;
      });
      setQuantity(quantity)

    }
    calculateQuantity()

  }, [cartItems]);

  const [data, setData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [searchData, setSearchData] = useState([])
  const [isPageVisible, setIsPageVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('https://mysuperweb.xyz/')
        setData(res.data)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [])

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    if (searchValue.trim() === '') {
      setSearchData([])
    } else {
      const filter = data.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
      setSearchData(filter)
    }
    
  }, [searchValue, data])

 
  

  const handleClosePage = () => {
    setIsPageVisible(false);
    
  };
  const handleOpenPage = () => {
    setIsPageVisible(true);
  };




  return (
    <div>
      {isPageVisible&&
      <div >
        <div >
          <div className='p-3 bg-body-tertiary' >

            <div className='row ' style={{ display: 'flex', alignItems: 'center' }}>
              <div className='col-md-3 p-3 ' style={{ textAlign: 'center' }}><img
                src="/images/ori-kangaroo-logo-with-tm-02.png"  // 根据实际路径调整
                alt="logo"
                width={'150px'}
                
              /></div>
              <div className='col-11 col-md-8'><InputGroup  >
                <InputGroup.Text><CiSearch /></InputGroup.Text>
                <Form.Control onChange={handleChange} aria-label="search" />
              </InputGroup></div>
              <div className='col-1 col-md-1'><IoMdClose onClick={handleClosePage} style={{cursor:'pointer'}}/></div>
            </div>


            <div style={{ paddingBottom: '40px' }}>


              <div className='row gy-5 pt-5'>


                {searchData.map((item) => (



<div className='col-6 col-md-3' key={item.id} >
                            <Link onClick={handleClosePage}  to={`/ProductDetailsC/${item.id}`}>
                                <div className="card" style={{ backgroundColor: '#E3F3FF', border: 'none', display: 'flex', justifyContent: 'end', borderRadius: '12%' }}>
                                    
                                    <Image  src={`/products/${item.image}`} fluid style={{ border: '3px #C0D0E0 dashed', borderRadius: '12%'}} />

                                    <div className="card-img-overlay p-0 text-end">
                                        {item.isSale ? (<div ><Button className="rounded-pill" variant="danger" style={{ backgroundColor: 'red' }}>Sale!</Button></div>) : null}


                                    </div>
                                </div>
                            </Link>

                            


                            <div style={{ textAlign: 'center',paddingTop:'10px' }}>
                                <div style={{ color: '#6EC1E4', textAlign: 'center',fontSize:'2vh',fontWeight:'bold' }}>{item.name}</div>
                                <div  style={{ color: '#6EC1E4', textAlign: 'center',fontSize:'1.9vh',fontWeight:'bold' }}>
                                    {item.offer_price > 0 ? <span style={{ paddingRight: '5px', opacity: '0.5' }} className='text-decoration-line-through'>{item.offer_price.toFixed(2)}MYR</span> : null}
                                    <span>{item.price.toFixed(2)}MYR</span>

                                </div>





                            </div>



                        </div>
                ))}

              </div>

            </div>
          </div>
        </div>
      </div>
      }



      
{!isPageVisible&&


        <Navbar expand="md" style={{zIndex:'5',position:'absolute',width:'100%'}}>
          <Container>

            <Navbar.Brand as={Link} to='/'>
              <img
                src="/images/ori-kangaroo-logo-with-tm-02.png"  // 根据实际路径调整
                alt="logo"
                width={'150px'}
                className='bounce-in-left'
              />
            </Navbar.Brand>

            <div style={{ display: 'flex', alignItems: 'center' }}>

              <CiSearch style={{ fontSize: '25px', margin: '0 6px',cursor:'pointer'}} className='d-block d-md-none' onClick={handleOpenPage}/>
              <Nav.Link as={Link} to='/ShoppingCart' style={{ position: 'relative' }} className='d-block d-md-none'>

                {quantity === 0 ? null :
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '8px' }}>
                    {quantity}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                }
                <CiShoppingCart style={{ fontSize: '25px', margin: '0 6px' }} />
              </Nav.Link>

              <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ border: 'none', padding: '0', fontSize: '25px', margin: '0 6px' }} >
                <CiMenuBurger />
              </Navbar.Toggle>

            </div>




            <Navbar.Collapse id="basic-navbar-nav">

              <Nav className="mx-auto" >
                
                <Nav.Link style={{color:props.color}} as={Link} to='/'  className={`me-3 NavLink ${isHomeActive ? 'active' : 'unactive'}`}>Home</Nav.Link>
                <Nav.Link style={{color:props.color}} as={Link} to='/AboutUs' className={`me-3 NavLink ${isAboutActive ? 'active' : 'unactive'}`}>About us</Nav.Link>
                <Nav.Link style={{color:props.color}} as={Link} to='/OurStory' className={`me-3 NavLink ${isOurStoryActive ? 'active' : 'unactive'}`}>Our Story</Nav.Link>
                <Nav.Link style={{color:props.color}} as={Link} to='/ShopNow' className={`me-3 NavLink ${isShopNowActive ? 'active' : 'unactive'}`}>Shop Now</Nav.Link>
                <Nav.Link style={{color:props.color}} as={Link} to='/ContactUs' className={`me-3 NavLink ${isContactUsActive ? 'active' : 'unactive'}`}>Contact Us</Nav.Link>

                <Nav.Link as={Link} to='/AdminHome'>Admin</Nav.Link>
              </Nav>
              <CiSearch style={{ fontSize: '25px', margin: '0 6px',cursor:'pointer',color:props.color }} className='d-none d-md-block' onClick={handleOpenPage}/>
              <Nav.Link as={Link} to='/ShoppingCart' style={{ position: 'relative' }}>

                {quantity === 0 ? null :
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '8px' }}>
                    {quantity}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                }
                <CiShoppingCart style={{ fontSize: '25px', margin: '0 6px',color:props.color }} className='d-none d-md-block' />
              </Nav.Link>
            </Navbar.Collapse>
                
          </Container>
          

        </Navbar>
      
        }


      </div>
    

  )
}
