import React from 'react'
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { GiKangaroo } from "react-icons/gi";

export default function Footer() {
    return (
        <div>
            <section style={{ backgroundColor: '#FFD6A9', padding: '20px' }}>
                <div>
                    <div className="row g-3">
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <Image src="/images/ori-kangaroo-logo-with-tm-02.png" fluid alt="logo" />
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3" style={{ color: 'black' }}>
                            <div className="fs-3 py-2 fw-bold">Contact Info</div>
                            <div className="py-2"><GiKangaroo style={{ fontSize: '30px' }}/>01117941993</div>
                            <div className="py-2"><GiKangaroo style={{ fontSize: '30px' }}/>0176873166</div>
                            <div className="py-2"><GiKangaroo style={{ fontSize: '30px' }}/>info@gmail.com</div>
                            <div className="py-2"><GiKangaroo style={{ fontSize: '30px' }}/>Malaysia,Melaka</div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3" style={{ color: 'black' }}>
                            <div className="fs-3 py-2 fw-bold">Quick Links</div>
                            <div className="py-2"><GiKangaroo style={{ fontSize: '30px' }}/><Link style={{ textDecoration: 'none', color: 'black' }}>Home</Link></div>
                            <div className="py-2"><GiKangaroo style={{ fontSize: '30px' }}/><Link style={{ textDecoration: 'none', color: 'black' }}>About us</Link></div>
                            <div className="py-2"><GiKangaroo style={{ fontSize: '30px' }}/><Link style={{ textDecoration: 'none', color: 'black' }}>Our Story</Link></div>
                            <div className="py-2"><GiKangaroo style={{ fontSize: '30px' }}/><Link style={{ textDecoration: 'none', color: 'black' }}>Shop Now</Link></div>
                            <div className="py-2"><GiKangaroo style={{ fontSize: '30px' }}/><Link style={{ textDecoration: 'none', color: 'black' }}>Contact Us</Link></div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3" style={{ color: 'black' }}>
                            <div className="fs-3 py-2 fw-bold">Newsletter</div>
                            <form action="">
                                <p>Subscribe For Latest Updates</p>
                                <input type="text" className="form-control" placeholder="Your Email" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                <button type="button" style={{ borderRadius: '25px', color: 'black', textTransform: 'capitalize' }} className="btn btn-warning px-4 my-2">Subscribe</button>
                            </form>
                            <div>

                                <div>
                                    <FaFacebook style={{ fontSize: '30px',margin:'5px' }} />
                                    <FaInstagram style={{ fontSize: '30px',margin:'5px' }} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="d-flex justify-content-center align-items-center fs-lg-6 pt-4 fw-bold" style={{ color: 'black' }}>© 2024 GROWTH ORGANICA INTERNATIONAL SDN. BHD. 202301041466 (1535385-P) | ALL RIGHTS RESERVED</div>
                </div>
            </section>
        </div>
    )
}
