import React from 'react'

export default function NotFound() {
  return (
    <div>
      404 <br></br>
      page not found
    </div>
  )
}
