import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Image } from 'react-bootstrap'
import Navbar from './navbar'
export default function OrderDetails() {
    const { id } = useParams();
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])

    useEffect(() => {
        const fetchData1 = async () => {
            try {
                const response = await axios.get('https://mysuperweb.xyz/');
                setData1(response.data);
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData1();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://mysuperweb.xyz/orderDetails');
                setData(response.data);
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData();
    }, []);
    



    const matchedProducts = {};
    data1.forEach(product => {
      matchedProducts[product.id] = product.name;
    });
    const matchedProducts1 = {};
    data1.forEach(product => {
      matchedProducts1[product.id] = product.image;
    });
  

    let total = 0


    return (

        <div>
            <Navbar/>

            <div className="container pt-3">
                <div className="cartSection row">
                    <div className='col-md-9' >


                        {
                            data.map((item, index) => {
                                if (String(item.OrderID) === String(id)) {
 
                                    const productName = matchedProducts[item.ProductID]; 
                                    const productImage = matchedProducts1[item.ProductID]; 
                                    
                        
                                    total=total+item.TotalPrice

                                    return (
                                        <React.Fragment key={index}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div className='col-4 col-md-2'><Image src={process.env.PUBLIC_URL +'/products/' +productImage} fluid /></div>
                                                <div className='col-8 col-md-10 row' style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className='col-md-6 py-1' style={{ color: '#8f8f8f', fontSize: '12px', textAlign: 'start' }}>{productName}</div>
                                                    <div className='col-md-1 py-1' style={{ fontSize: '10.8px', fontWeight: 'bold', textAlign: 'start' }}>x{item.Quantity}</div>
                                                    <div className='col-md-2 py-1' style={{ fontSize: '10.8px', fontWeight: 'bold', textAlign: 'start' }}>{item.UnitPrice.toFixed(2)} MYR</div>
                                                </div>
                                            </div>
                                            <hr style={{ margin: '20px 0 20px 0' }} />
                                            
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })
                        }
                    </div>
                    <div className='col-md-3'>
                        <div>

                        <hr></hr>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>Subtotal</div>
                                <div>{total.toFixed(2)} MYR</div>
                            </div>
                            <hr></hr>



                        </div>
                    </div>

                </div>

            </div>



        </div>
    )
}
