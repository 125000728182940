import React, { useEffect, useRef, useState } from 'react';
import Nav from './navbar.js'
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Popconfirm, message } from 'antd';
import Highlighter from 'react-highlight-words';
import axios from 'axios';


export default function DeleteProduct() {

    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState([true])


    const confirm = (productId) => {
        console.log(productId);

        axios.delete('https://mysuperweb.xyz/delete', { data: { productId: productId } })
            .then(response => {
                // 在成功删除产品后执行的操作，例如更新页面的产品列表
                console.log(response.data);
                message.success('successful deletion');
                setTimeout(() => {
                    window.location.reload();
                  }, 1000); // 2秒延迟1
                
            })
            .catch(error => {
                // 处理删除产品失败的情况
                console.error('Error deleting product:', error);
            });
    };

    const cancel = (e) => {
        console.log(e);
        message.error('failed deletion');
    };


    useEffect(() => {
        const fetchdata = async () => {

            try {
                const response = await axios.get('https://mysuperweb.xyz/')

                setApiData(response.data)
            }

            finally {
                setLoading(false)
            }

        }
        fetchdata()
    },[])



    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [

        {
            title: 'Products',
            dataIndex: 'image',
            key: 'image',
            width: '10%',
            render: (text) => <img src={`products/${text}`} alt="product" style={{ width: '100px' }} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ...getColumnSearchProps('name'),
            render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: '30%',
            ...getColumnSearchProps('price'),
            render: (text) => <span style={{ fontSize: '10px' }}>{text.toFixed(2)} MYR</span>,
        },
        {
            
                title: 'Action',
                key: 'operation',
                fixed: 'right',
                width: 80,
                render: (record) => <Popconfirm
                title="Delete the product"
                description="Are you sure to delete this product?"
                onConfirm={()=>confirm(record.id)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
                >
                <Button danger>Delete</Button>
                </Popconfirm>,
            
        }


    ]
    return (
        <div>
            <Nav />
            {loading && <p>Loading...</p>}

            <Table columns={columns} dataSource={apiData} scroll={{ x: 'max-content' }} />
        </div>
    )
}
