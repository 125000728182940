import React from 'react'
import Navbar from '../components/navbar'
import Image from 'react-bootstrap/Image';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet-async';

export default function OurStory() {
    const currentDomain = window.location.origin;
    return (
        <div>
            <Helmet>
                <title>Our Story</title>
                <meta name="description" content="Discover our inspiring story." />
                <link rel='canonical' href={currentDomain+'/OurStory'} />
            </Helmet>
            
            <Navbar page={'OurStory'} />
            <section style={{ backgroundImage: 'linear-gradient(to bottom, #fafafa,#dfedfc)',paddingTop:'50px' }}>
            
                <div style={{ textAlign: 'center', padding: '30px' }}>
                    <Image src="images/kangaroo-top-img.png" width={'57px'} fluid />
                    <p className='fs-2 fw-bold'>Our Story</p>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className='rounded-4' style={{ margin: '20px', border: '5px #C0D0E0 dashed', borderRadius: '12%', overflow: 'hidden' }}>

                        <div className='row' style={{ maxWidth: '1200px', backgroundImage: `url("images/annie-spratt-zA7I5BtFbvw-unsplash.jpg")` }}>
                            <div className='col-1'></div>

                            <div className='col-10' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '40px' }}>
                                <div >
                                    <h3 style={{ color: '#ff8900', padding: '5px' }}>Welcome to Kangaroo Care</h3>
                                    <h3 style={{ color: '#ff8900', padding: '5px' }}>Where Passion Meets Baby Care Excellence</h3>
                                    <p style={{ padding: '5px' }} className='fw-bold'>
                                        Hello and thank you for visiting Kangaroo Care, the culmination of our passion and dedication to providing the best care for your little ones. We are thrilled to share the story behind our commitment to creating a product that prioritizes the well-being of your precious babies.
                                    </p>
                                    <p style={{ padding: '5px' }} className='fw-bold'>
                                        Our journey into the world of baby care began with a profound desire to develop a cream that not only soothes, comfort and protects but also promotes the happiness of every baby. Recognizing the delicate nature of a baby’s skin, I embarked on a two-year-long research and development process to formulate a product that stands out in quality and effectiveness.
                                    </p>
                                </div>

                            </div>
                            <div className='col-1'></div>


                        </div>


                    </div>
                </div>


                <section style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px' }}>
                    <div className='row' style={{ maxWidth: '1200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                        <div className='col-md-6' ><Image src="images/kangaroo-product-img.png" width={'100%'} fluid style={{ borderRadius: '12%', maxWidth: '450px' }} /></div>
                        <div className='col-md-6'>
                            <p style={{ padding: '5px' }} className='fw-bold'>
                                During those years, my team and I delved into the intricacies of baby skin care, exploring natural ingredients, gentle formulations, and advanced technology. Our dedication was fuelled by the understanding that every baby deserves the utmost care, and every parent deserves a product they can trust.
                            </p>
                            <p style={{ padding: '5px' }} className='fw-bold'>
                                The inspiration behind Kangaroo Care comes from the nurturing essence of kangaroos who cradle their joeys close to provide warmth, comfort, and protection. It is this same essence that we infused into our baby relief cream – a product designed to embrace your baby with the gentle care and love they deserve.
                            </p>

                        </div>
                    </div>
                </section>

                <section style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px' }}>
                    <div className='row' style={{ maxWidth: '1200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


                        <div className='col-md-6'>
                            <p style={{ padding: '5px' }} className='fw-bold'>
                                Our cream is more than just a skincare solution,it is a testament to our commitment to your baby’s well-being. We prioritize safety, efficacy, and the joy that comes from knowing your baby is comfortable and happy. Kangaroo Care Baby Relief Cream is a labor of love, and we are excited to share the results of our passion with you.
                            </p>
                            <p style={{ padding: '5px' }} className='fw-bold'>
                                I understand the importance of choosing products that align with parents values and expectations. Kangaroo Care is more than just a brand; it’s a promise to provide your baby with the care they deserve. Join us on this journey, and let every application of Kangaroo Care Baby Relief Cream bring a smile to your baby’s face.
                            </p>

                        </div>

                        <div className='col-md-6' ><Image src="images/kangaroo-our-story.png" width={'100%'} fluid style={{ borderRadius: '12%', maxWidth: '550px' }} /></div>
                    </div>
                </section>


                <section style={{ padding: '100px' }}>

                    <h4 style={{ textAlign: 'center' }}>Thank you for entrusting us with your precious moments<br></br> and  allowing Kangaroo Care to be a part of your baby's care routine.</h4>

                    <p style={{ textAlign: 'center' }}>With love, Emily Tiow<br></br> Founder of Kangaroo Care</p>

                </section>


            </section>

            <Footer />
        </div>
    )
}
