import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams,useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Navbar from './navbar';
import { Form, Button, Image } from 'react-bootstrap'

export default function UploadForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const name = useRef(null);
  const price = useRef(null);
  const offerPrice = useRef(null);
  const isSale = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();



  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile); // 更新文件状态
    // 创建临时URL
    const imageUrl = URL.createObjectURL(uploadedFile);
    // 设置照片URL
    setImageUrl(imageUrl);
  };


  useEffect(() => {
    axios.get('https://mysuperweb.xyz/')
      .then(res => setData(res.data))
      .catch(err => console.log(err))
  }, [id])






  const handleUpload = async (e) => {
    e.preventDefault(); // 阻止默认的表单提交行为

    const formData = new FormData();
    formData.append('image', file);
    formData.append('name', name.current.value);
    formData.append('price', price.current.value);
    formData.append('offerPrice', offerPrice.current.value);
    formData.append('isSale', isSale.current.value);

    try {
      const response = await axios.post(`https://mysuperweb.xyz/change/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      messageApi.open({
        type: 'success',
        content: 'Successfully updated',
      });
      console.log('File uploaded successfully:', response.data);
      
      setTimeout(() => {
        navigate('/Product');
      }, 1000); // 2秒延迟1
  
  
    } catch (error) {
      messageApi.open({
        type: 'warning',
        content: 'Update failed',
      });
      console.error('Error uploading file:', error);
    }
  }


  return (
    <div>
      <Navbar />
      {contextHolder}









      {data.map(item => {
        // 只渲染满足条件的数据
        if (String(item.id) === String(id)) {
          return (
            <div key={item.id} style={{ display: 'flex', justifyContent: 'center',margin:'10px' }}>
              
              <div style={{ border: '3px #C0D0E0 dashed', borderRadius: '5%' ,padding:'10px'}}>
              <h3 style={{textAlign:'center'}}>EDIT PRODUCT</h3>
                <form onSubmit={handleUpload}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}><Image  src={imageUrl ? imageUrl : `/products/${item.image}`} width={'200px'} alt="product" fluid /></div>
          
                  <div style={{ display: 'flex', justifyContent: 'center',padding:'20px 0' }}><input type='file' name='image' onChange={handleFileChange} /></div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <Form.Label htmlFor="name">Name</Form.Label>
                      <Form.Control type="text" id="name" ref={name} defaultValue={item.name} />
                    </div>

                    <div className='col-md-6'>
                      <Form.Label htmlFor="price">Price</Form.Label>
                      <Form.Control type='text' ref={price} defaultValue={item.price} id="price" />
                    </div>

                    <div className='col-md-6'>
                      <Form.Label htmlFor="offerPrice">Offer price</Form.Label>
                      <Form.Control type='text' ref={offerPrice} defaultValue={item.offer_price} id="offerPrice" />
                    </div>

                    <div className='col-md-6'>
                      <Form.Label htmlFor="isSale">is Sale?</Form.Label>
                      <Form.Control type='text' ref={isSale} defaultValue={item.isSale} id="isSale" />
                    </div>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center' ,padding:'20px 0' }}><Button style={{ width: '30%' }} type='submit' variant="outline-secondary">EDIT</Button></div>


                </form>


              </div>
            </div>

          );
        }
        // 不满足条件的数据不渲染
        return null;
      })}



    </div>
  );
}
