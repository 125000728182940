import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/navbar.css'; // 引入您的 CSS 文件
import { Link } from 'react-router-dom';





export default function Naavbar(props) {



  return (
    <div className='sticky-top'>
      <Navbar expand="md" className="bg-body-tertiary" >
        <Container>

          <Navbar.Brand as={Link} to='/AdminHome'>
            <img
              src={process.env.PUBLIC_URL + "/images/ori-kangaroo-logo-with-tm-02.png"}  // 根据实际路径调整
              alt="logo"
              width={'150px'}
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav " />

          <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="mx-auto">

              <Nav.Link as={Link} to='/AdminHome' >Home</Nav.Link>

            </Nav>
          </Navbar.Collapse>

        </Container>


      </Navbar>

    </div>
  )
}
