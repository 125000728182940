import React from 'react';
import Navbar from '../components/navbar'
import Image from 'react-bootstrap/Image';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet-async';
import ProductList from '../components/ProductList';



export default function ShopNow() {


    
    const currentDomain = window.location.origin;
    return (
  
        <div>
            <Helmet>
                <title>Shop Now</title>
                <meta name="description" content="Shop our latest products now."/>
                <link rel='canonical' href={currentDomain+'/ShopNow'}/>
            </Helmet>

       
            <Navbar page={'ShopNow'} />
            <section className='container-fluid' style={{ backgroundImage: 'linear-gradient(to bottom, #fafafa,#dfedfc)',paddingTop:'50px' }}>
            
            
                <div style={{ textAlign: 'center', padding: '30px' }}>
                    <Image src="images/kangaroo-top-img.png" width={'57px'} fluid />
                    <p className='fs-2 fw-bold'>Shop Now</p>
                </div>

                <ProductList/>

            </section>

            <Footer />

        </div>
    )
}
